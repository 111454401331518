import React, {Component} from 'react'
import classes from './WorkImages.module.css'
import "animate.css/animate.min.css"; 
import {GoProject} from 'react-icons/go'
import SkelMahj from '../images/Skel_Mahj.jpg'
import BoomX from '../images/BoomerangX_Logo.jpg'
import GigP from '../images/GigPosters.jpg'
import Dang from '../images/Dang_Logo.jpg'


class WorkImages extends Component {
     render(){
        return (
            <React.Fragment>
            <main className={classes.work_grid} id="work">
                <div className={classes.grid_item}>
                    <a href={"https://boomerang-x.com"} target="_blank" rel="noopener noreferrer">
                        <img className={classes.image} src={BoomX} alt='gameImage'/>
                    </a>
                        <p className={classes.caption}>GAMES</p>
                </div>
                <div className={classes.grid_item}>
                    <a href={"https://instagram.com/blaqkcoral"} target="_blank" rel="noopener noreferrer">
                        <img className={classes.image} src={SkelMahj} alt='illustrationsImage'/>
                    </a>
                        <p className={classes.caption}>ILLUSTRATIONS</p>
                </div>
                <div className={classes.grid_item}>
                    <a href={"https://janicehony.wixsite.com/blaqkdamask/poster"} target="_blank" rel="noopener noreferrer">
                        <img className={classes.image} src={GigP} alt='postersImage'/>
                    </a>
                        <p className={classes.caption}>POSTERS</p>
                </div>
                <div className={classes.grid_item}>
                    <a href={"https://twitter.com/videodang"} target="_blank" rel="noopener noreferrer">
                        <img className={classes.image} src={Dang} alt='DangImage'/>
                    </a>
                        <p className={classes.caption}>DANG!</p>
                </div>
            </main>
               
            </React.Fragment>
     )    
    }
}
export default WorkImages

// class WorkImages extends Component {
//      render(){
//         return (
//             <React.Fragment>
//             <div className={classes.work_container}>
//                 <img className={classes.image} src={SkelMahj} />
//                 <div className={classes.h1}>
//                     {/* <h1>H</h1> */}
//                 </div>
//             </div>
//             <div className={classes.work_container}>
//                 <img className={classes.image} src={BoomX} />
//             </div>  
//             <div className={classes.work_container}>
//                 <img className={classes.image} src={GigP} />
//             </div> 
//             </React.Fragment>
//      )    
//     }
// }
// export default WorkImages