import React, { Component } from 'react';
import './Sidebar.css';
import { HashLink as Link } from 'react-router-hash-link';
import {  FiExternalLink } from 'react-icons/fi';
import logo from '../images/Logo_test.ico'
import Insta from '../images/instagram_icon.png'
import Twitter from '../images/twitter_icon.png'

class Sidebar extends Component {
    render() {
       
        return (
            <div className="sidebar">
                <img className="sidebar-logo" src={logo} />
                <h1><Link smooth to="/#start" className="h1_links">J.ho</Link></h1>
                {/* <p className="title"> GAME MAKER | 2D & 3D </p> */}
                {/*<p style={{color:'240, 240, 240',fontWeight:'bold'}} className="gmail"> janice@dang.computer </p>*/}
                <ul className="sidebar-nav">
                    <li className="sidebar-nav-items"><Link smooth to="/#work" className="links">WORK</Link></li>
                    <li className="sidebar-nav-items"><Link smooth to="/#about" className="links">ABOUT</Link></li>
                    {/* <li className="sidebar-nav-items"><Link smooth to="/#education" className="links">RESUME</Link></li> */}
                    <li className="sidebar-nav-items"><Link smooth to="/#contact" className="links">CONTACT</Link></li>
                    {/* <li className="sidebar-nav-items"><a href="http://www.bloggingpeek.com" target="_blank" rel="opener noreferrer" className="links"> Blog<FiExternalLink/></a></li> */}
                </ul>
                <div className="sidebar-nav-social">
                    <a href={"https://instagram.com/blaqkcoral"} target="_blank" rel="noopener noreferrer">
                        <img className="sidebar-nav-social-items" src={Insta} alt="instagramIcon" />
                    </a>
                    <a href={"https://twitter.com/_jayho"} target="_blank" rel="noopener noreferrer">
                        <img className="sidebar-nav-social-items" src={Twitter} alt="twitterIcon" />
                    </a>
                    {/* <img className="sidebar-nav-social-items" src={Insta} />
                    <img className="sidebar-nav-social-items" src={Twitter} /> */}
                </div>
                {/* <div className="flip-card-back">
                    <ul className="sidebar-nav">
                        <li className="sidebar-nav-icons"><a href="https://www.linkedin.com/in/sachin-mittal-476174158?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BoRhF2EUsQJ%2BygJpLEZb%2FFA%3D%3D" rel="opener noreferrer" target="_blank" class="fa fas fa-linkedin fa-lg"></a></li>
                        <li className="sidebar-nav-icons"> <a href="https://github.com/mittalsam98" rel="opener noreferrer" target="_blank" class="fa fas fa-github fa-lg"></a></li>
                        <li className="sidebar-nav-icons"> <a href="https://twitter.com/Sachin_Mittal98" rel="opener noreferrer" target="_blank" className="fa fas fa-twitter fa-lg"></a></li>
                        <li className="sidebar-nav-icons"> <a href="https://www.instagram.com/decent_sachin.mittal/" rel="opener noreferrer" target="_blank" class="fa fas fa-instagram fa-lg"></a></li>
                        <li className="sidebar-nav-icons"> <a href="mailto:mittalsam98@gmail.com" rel="opener noreferrer" target="_blank" class="fa fas fa-envelope fa-lg"></a></li>
                        <li className="sidebar-nav-icons"> <a href="https://www.facebook.com/profile.php?id=100011989067867" rel="opener noreferrer" target="_blank" class="fa fas fa-facebook fa-lg"></a></li>
                    </ul>
                </div> */}
              
            </div>
        )
    }
}

export default Sidebar