import React, { Component } from 'react'
import classes from './About.module.css';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css"; 
import ProfGif from '../images/Profile_Animation.gif'


class About extends Component {
    render() {
        return (
            <div className={classes.box} id="about">
                {/* <ScrollAnimation offset="0" animateIn="fadeInLeft" duration='2.4' animateOnce="true" initiallyVisible ={true}> */}
                <img className={classes.profileimg} src={ProfGif} alt="profgif"/>
                <div className={classes.text}>
                    {/* <span className={classes.head}>Who Am I?</span> */}
                    <div className={classes.About}>
                        <p> Hi my name is Janice Ho. I'm a multimedia artist originally from NYC but currently working with DANG! Studios, based in Troy, NY. </p>
                        <p> I make games, 2D illustrations, 3D models, but enjoy creating in all sorts of mediums! </p>
                        <p> I enjoy hiking, live music, riding anything with wheels, a sweet & salty combo, & when the sunbeams hit my room just right. If that sounds like you...or not hit me up! </p>
                    </div>
                </div>
                {/* </ScrollAnimation> */}
            </div>
        )
    }
}

export default About;