import React, { Component } from 'react'
import classes from './Contact.module.css';
import "animate.css/animate.min.css";

class Contact extends Component {
    render (){
        return (
            <div className={classes.box} id="contact">
                <h className={classes.head}> GET IN TOUCH </h>
                <p className={classes.email}> janicehony@gmail.com </p>
            </div>
        )
    }
}
export default Contact;