import React, { Component } from 'react'
import classes from './Resume.module.css';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

class Resume extends Component {
  render() {
    return (
      <div className={classes.box} id="about">
        <ScrollAnimation offset="0" animateIn="fadeInLeft" duration='2.4' animateOnce="true" initiallyVisible={true}>
        <hr width="100%" color="#000000" size="1" />
          {/* <span className={classes.head}>HONORS</span> */}
          {/* <h2 className={classes.heading}>HERE ARE SOME OF MY EXPERTISE</h2> */}
          <div className={classes.Resume}>
            <section className={classes.resumeitem}>
              <h1 className={classes.heading}>DANG! STUDIOS</h1>
              <p>
                <ul className={classes.resumelist}>
                  <li>Boomerang X</li>
                  <li>IO Interloper</li>
                </ul>
              </p>
            </section>
            <section className={classes.resumeitem}>
              <h2 className={classes.heading}>EDUCATION</h2>
              <p>
                <ul className={classes.resumelist}>
                  <li>Rensselaer Polytechnic Institute</li>
                  <li>B.S. Games, Simulation Arts, & Sciences | Arts</li>
                  <li>B.S. Electronic Media, Arts, & Communication | Graphic Design</li>
                </ul>
              </p>
            </section>
            <section className={classes.resumeitem}>
              <h3 className={classes.heading}>HONORS</h3>
              <p>
                <ul className={classes.resumelist}>
                  <li>5 Startup Founders 25 or Younger ’21</li>
                  <li>E3 College Games Competition Finalist ’18</li>
                  <li>IGDA Women in Games Ambassador '18</li>
                  <li>NYS Game Dev Challenge 3rd Place Student ‘17</li>
                  <li>RPI Gamefest: Outstanding Art Direction ‘17</li>
                  <li>GDC Game Narrative Review Winner ‘17</li>
                </ul>
              </p>
            </section>
          </div>
        </ScrollAnimation>
      </div>
    )
  }
}

export default Resume;